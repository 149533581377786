// File
//
// Custom file input.

.dropzone {
    height: auto;

    &-list {
        list-style: none;
        padding: 0;
    }

    &-input {
        display: none;
    }

    &-label {
        position: relative;
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border: 2px dashed #ccc;
        text-align: center;
        height: auto;
        cursor: pointer;
        margin-bottom: 0;

        &.is-dragover {
            border-color: black;
        }

        &::before {
            content: '';
            background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22white%22%20class%3D%22bi%20bi-card-image%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20d%3D%22M6.002%205.5a1.5%201.5%200%201%201-3%200%201.5%201.5%200%200%201%203%200z%22%2F%3E%0A%20%20%3Cpath%20d%3D%22M1.5%202A1.5%201.5%200%200%200%200%203.5v9A1.5%201.5%200%200%200%201.5%2014h13a1.5%201.5%200%200%200%201.5-1.5v-9A1.5%201.5%200%200%200%2014.5%202h-13zm13%201a.5.5%200%200%201%20.5.5v6l-3.775-1.947a.5.5%200%200%200-.577.093l-3.71%203.71-2.66-1.772a.5.5%200%200%200-.63.062L1.002%2012v.54A.505.505%200%200%201%201%2012.5v-9a.5.5%200%200%201%20.5-.5h13z%22%2F%3E%0A%3C%2Fsvg%3E');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: block;
            font-weight: 900;
            font-size: 2rem;
            color: #ccc;
            margin-bottom: .75rem;
            border-left: none;
            background-color: transparent;
            height: 4rem;
        }

        &::after {
            display: none;
        }
    }

    &-item {
        background-color: #222222;
        margin-bottom: 1rem;
        border-radius: 5px;
        text-align: center;
        padding: 1rem;

        .draggable {
            cursor: grab;
        }
    }

    &-trash {
        background: none;
        border: none;
        padding: 0;

        i {
            font-size: 1rem;
            color: white;
        }
    }

    .col-md-auto {
        align-items: center;
        display: flex;
    }
}
