.dataTables_wrapper {
    color: white !important;

    label {
        color: white;
    }

    .dt-button {
        background-color: black !important;
        background-image: none;
        border-radius: 5px;
        font-weight: 600;
        color: white;
        border: none;

        &:hover {
            background-color: darkgray !important;
            background-image: none !important;
            color: black !important;
            border: none !important;
        }
    }

    input {
        border-radius: 5px;
        color: black;
    }

    table {
        width: 100% !important;
        margin-bottom: 1rem;
        padding-top: 1rem;

        thead {
            background-color: #222222;

            th {
                border: 1px solid gray;
                text-align: center;
            }
        }

        tbody {
            tr {
                background-color: transparent;

                td {
                    border: 1px solid gray;
                    vertical-align: middle;
                    text-align: center;
                    padding: .5rem;

                    a {
                        color: white;
                        display: block;
                    }

                    .bi-arrows-move {
                        display: block;
                        cursor: move;
                    }
                }
            }
        }
    }

    .dataTables_info {
        color: white;
    }

    .dataTables_paginate {
        span {
            margin: auto .3rem;
        }
    }

    .paginate_button {
        background-color: black !important;
        background-image: none !important;
        border-radius: 5px !important;
        font-weight: 600 !important;
        border: none !important;

        &.current {
            background-color: black !important;
            background-image: none !important;
            border-radius: 5px !important;
            font-weight: 600 !important;
            border: none !important;
        }

        &.disabled {
            opacity: .3;
        }
    }
}


@media only screen and (max-width: 768px) {
    main {
        padding-top: 0;
    }

    h1, h2 {
        font-size: 1.5rem;
    }

    .form-text {
        font-size: .7rem;
    }

    .btn-dark {
        margin-top: 1rem;
        width: 100%;
    }

    table {
        margin-bottom: 0 !important;
    }

    .card-body {
        padding: .7rem;
    }
}
