// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import "../loading";

@import "datatables";
@import "sortable";

@import "includes";

main, .container-fluid {
    max-width: 1920PX;
    margin: auto;
}

h1, h2 {
    font-size: 2rem;
}

hr {
    border-color: gray;
}

.form-text {
    padding: .2rem .5rem !important;
    background-color: gray;
    border-radius: 5px;
    width: fit-content;
}

.btn-dark {
    background-color: black;
    padding-inline: 2rem;
    font-size: 1rem;
}

.ck-toolbar_grouping {
    background-color: #222222 !important;

    button {
        color: white !important;

        &.ck-disabled {
            color: white !important;

            &:hover {
                background-color: transparent !important;

                svg {
                    color: white !important;
                }
            }
        }

        svg {
            color: white !important;
        }

        &:hover {
            background-color: white !important;
            color: black !important;

            svg {
                color: black !important;
            }
        }
    }
}
